import React from 'react'

const StreamTemplate = (props) => {
  
  const jQuery = '<script src="https://code.jquery.com/jquery-2.2.4.min.js" integrity="sha256-BbhdlvQf/xTY9gja0Dq3HiwQF8LaCRTXxZKRutelT44=" crossOrigin="anonymous"></script>';
  const bootstrap = '<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-giJF6kkoqNQ00vy+HMDP7azOuL0xtbfIcaT9wjKHr8RbDVddVHyTfAAsrekwKmP1" crossOrigin="anonymous"></link>';
  const styles = '<link href="https://www.fgr.co.uk/streamtemplate.css" rel="stylesheet"></link>';
  const markup = `
    <div class="topbar">
      <div class="topbar__wrapper">
        <a class="topbar__link" href="https://www.fgr.co.uk">Forest Green Rovers, The World\'s Greenest Football Club</a>
        <div class="topbar__right">
          Powered by
          <img class="topbar__right__image" src="https://www.fgr.co.uk/ecotricity-white.png" alt="Ecotricity" />
        </div>
      </div>
    </div>
  `

  return (
    <React.Fragment>
        <div dangerouslySetInnerHTML={{__html: `<!--HEAD:BEGIN-->${jQuery}${bootstrap}${styles}<!--HEAD:END-->`}} />
        <div dangerouslySetInnerHTML={{__html: `<!--HEADER:BEGIN-->${markup}<!--HEADER:END-->`}} />
        <div dangerouslySetInnerHTML={{__html: `<!--FOOTER:BEGIN--><!--FOOTER:END-->`}} />
    </React.Fragment>
  )
}

export default StreamTemplate